rodijco.controller 'BufferController', [
  '$scope'
  'httpService'
  (scope, httpService) ->

    scope.toDownload

    httpService.get 'buffer', (response) ->
      return null if not response.success

      scope.toDownload = response.data?.certificates?.length

]
